import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { delay, map } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';
import { HostListener } from "@angular/core";
import { DeviceDetectorService } from 'src/app/services/device-detector.service';
import { UtilsService } from 'src/app/shared/utils.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers,
 
})
export class HomeComponent implements OnInit {
  categories: Observable<any[]>;
  showNavigationArrows = false;
	showNavigationIndicators = false;
	images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);
  size: SCREEN_SIZE;
  scrHeight:any;
  scrWidth:any;
  cat_gioithieu: Observable<any[]>;
  cat_nghethuat: Observable<any[]>;
  isMobile: boolean;
  screenSize: { width: number; height: number };

  constructor(
    public db: AngularFireDatabase,
    private title: Title,
    private meta: Meta,
    config: NgbCarouselConfig,
    private responsive: BreakpointObserver,
    private resizeSvc: ResizeService,
    private metaService: Meta,
    private deviceDetector: DeviceDetectorService,
    private utilsService: UtilsService
  ) {
    
    //  // Kiểm tra thiết bị và kích thước màn hình
    //  this.isMobile = this.deviceDetector.isMobileDevice();
    //  this.screenSize = this.deviceDetector.getScreenSize();

    //  this.responsive.observe([Breakpoints.Handset, Breakpoints.Tablet])
    //   .subscribe(result => {
    //     if (result.matches) {
    //       this.isMobile = true;
    //     } else {
    //       this.isMobile = false;
    //     }
    //   });

      // this.isMobile = true;
    this.categories = this.db.list('/categories', ref => ref.orderByChild('nghethuat').equalTo(false)).valueChanges().pipe(
      map(categories => 
        (categories as any[]).sort((a, b) => b.weight - a.weight) // Sắp xếp tăng dần
      )
    );
    this.cat_gioithieu = this.db.list('/categories', ref => ref.orderByChild('gioithieu').equalTo(true)).valueChanges();
    this.cat_nghethuat = this.db.list('/categories', ref => ref.orderByChild('nghethuathome').equalTo(true)).valueChanges();
    // this.categories = db.list('/categories', ref => ref.orderByChild('weight').limitToLast(50)).valueChanges();
    // customize default values of carousels used by this component tree
		config.showNavigationArrows = false;
		config.showNavigationIndicators = false; 
  }

  processImageUrl(url: string): string {
    return this.utilsService.insertThumbAtEncodedSlash(url);
  }

  ngOnInit() {
    this.title.setTitle('Nowa Architects');
    this.meta.updateTag({ content: 'Nowa Architects' }, "name='description'");
    // this.isMobile = this.deviceDetector.isMobileDevice();
    console.log('Is Mobile Device:', this.isMobile);
  }
  

  getCategoryImage(category: any): string {
    if (category?.thumbnail) {
      return this.isMobile ? this.processImageUrl(category.thumbnail) : category.thumbnail;
    }
    return '../../assets/placeholder.jpg';
  }

  

  

}
