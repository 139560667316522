import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute }    from '@angular/router';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { GlobalService } from './services/global.service';
import { LocalCartService } from './services/localcart.service';
import { SCREEN_SIZE } from './size-detector/screen-size.enum';
import { ResizeService } from './size-detector/resize.service';

import { delay } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  nav: Observable<any>;
  theme: Observable<any>;
  user: Observable<firebase.default.User>;
  size: SCREEN_SIZE;
  scrHeight:any;
  scrWidth:any;


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight =0;
        this.scrWidth =0;
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log(this.scrHeight, this.scrWidth);
        if (this.scrWidth >500 && this.scrWidth <=800)
          this.metaService.updateTag({
            name: 'viewport',
            content: 'width=device-width, initial-scale=0.2'
          }, 'name=viewport');
        else  if (this.scrWidth >800 && this.scrWidth <=3000)
        this.metaService.updateTag({
          name: 'viewport',
          content: 'width=device-width, initial-scale=0.365'
        }, 'name=viewport');
        else  if (this.scrWidth >3000)
          this.metaService.updateTag({
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }, 'name=viewport');
  }

  
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    private resizeSvc: ResizeService,
    private metaService: Meta
  ) {
    this.nav = db.list('/menus/nav').valueChanges();
    this.theme = db.object('/theme').valueChanges();
    
    // this.metaService.updateTag({
    //   name: 'viewport',
    //   content: 'width=device-width, initial-scale=1'
    // }, 'name=viewport');

    // this.getScreenSize();

    this.user = afAuth.authState;
    this.user.subscribe(currentUser => {

      // this.resizeSvc.onResize$
      // .pipe(delay(10))
      // .subscribe(x => {
      //   this.size = x;
      //   console.log('aaaaaaaa'+ this.size)
      // });

      globalService.user.next(currentUser);

      if (currentUser) {
        this.db.object('/users/' + currentUser.uid).update({
          uid: currentUser.uid,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
          status: 'active'
        });

        this.db.object('/users/' + currentUser.uid).valueChanges().subscribe((user:any) => {
          if (user.cart) {
            globalService.cart.next(user.cart);
          }
        });
      }

      if (!currentUser && this.localCart.cartHasItems()) {
        this.globalService.cart.next(this.localCart.cartGetItems());
      }
    });
  }

  login() {
    this.afAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
  }

  logout() {
    this.globalService.cart.next(null);
    this.globalService.order.next(null);
    this.localCart.clearAll();
    this.afAuth.signOut();
  }
}
