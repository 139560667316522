import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
declare var $: any;

import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';

@Component({
  selector: 'product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent implements OnInit {
  products: Observable<any[]>;
  // categories: Observable<any[]>;
  category: Observable<any[]>;
  categoryObject: any;
  categoryName: string;
  categoryProducts: Observable<any[]>;
  @Input() categoryInput: any;
  size: SCREEN_SIZE;
  isMobile: boolean;

  constructor(
    public db: AngularFireDatabase,
    public route: ActivatedRoute,
    public router: Router,
    private title: Title,
    private meta: Meta,
    private resizeSvc: ResizeService,
    private deviceDetector: DeviceDetectorService,
                private utilsService: UtilsService
  ) {
   
    this.products = db.list('/products', ref => ref.orderByChild('weight').limitToLast(999)).valueChanges();   
    this.categoryObject = {};
  }

  ngOnInit() {
    // this.isMobile = this.deviceDetector.isMobileDevice();
    console.log('Is Mobile Device:', this.isMobile);

    $(document).ready(function() {
      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });
    });

    if (this.categoryInput) {
      this.category = this.categoryInput;
      this.categoryObject.slug = this.categoryInput.slug;
      this.categoryObject.name = this.categoryInput.name;
      this.categoryObject.products = Object.keys(this.categoryInput.products);
      this.products.subscribe((p:any) => {
        this.categoryProducts = p.filter((item) => {
          return item.category === this.categoryInput.entityKey;
        });
      });
    } else {
      this.route.params.subscribe((params: Params) => {
        this.category = this.db.list('/categories', ref => ref.orderByChild('slug').equalTo(params.slug)).valueChanges();

        this.category.subscribe((cat:any) => {
          this.categoryObject.slug = cat[0].slug;
          this.categoryObject.name = cat[0].name;
          this.categoryObject.products = Object.keys(cat[0].products);
          this.products.subscribe((p:any) => {
            this.categoryProducts = p.filter((item:any) => {
              return item.category === cat[0].entityKey;
            });
          });

          this.title.setTitle(this.categoryObject.name);
          this.meta.addTag({ name: 'description', content: 'View all products in the ' + this.categoryObject.name + ' category' });
        });
      });
    }
  }

  processImageUrl(url: string): string {
    return this.utilsService.insertThumbAtEncodedSlash(url);
  }

  getProductImage(product: any): string {
    if (product?.thumbnail) {
      return this.isMobile ? this.processImageUrl(product.thumbnail) : product.thumbnail;
    }
    return '../../assets/placeholder.jpg';
  }

  // getProductImage(product:any) {
  //   if (product && product.thumbnail) {
  //     return product.thumbnail;
  //   } else {
  //     return '../../assets/placeholder.jpg';
  //   }
  // }
}
