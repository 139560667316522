import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
// import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgFor, NgIf } from '@angular/common';
declare var $: any;
import { delay, map } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';


@Component({
  selector: 'product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss'],
  // providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})
export class ProductCategoriesComponent implements OnInit {
  categories: Observable<any>;
  showNavigationArrows = false;
	showNavigationIndicators = false;
	images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);
  size: SCREEN_SIZE;
  isMobile: boolean;

  constructor(
    public db: AngularFireDatabase,
    private title: Title,
    private meta: Meta,
    // private metaService: Meta,
    private resizeSvc: ResizeService,
     private deviceDetector: DeviceDetectorService,
            private utilsService: UtilsService
    // config: NgbCarouselConfig
  ) {
    
    this.categories = this.db.list('/categories', ref => ref.orderByChild('nghethuat').equalTo(false)).valueChanges().pipe(
      map(categories => 
        (categories as any[]).sort((a, b) => b.weight - a.weight) // Sắp xếp tăng dần
      )
    );
     
  }

  processImageUrl(url: string): string {
    return this.utilsService.insertThumbAtEncodedSlash(url);
  }

  ngOnInit() {
    this.title.setTitle('Nowa Architects');
    this.meta.updateTag({ content: 'Nowa Architects' }, "name='description'");
    // this.isMobile = this.deviceDetector.isMobileDevice();
    console.log('Is Mobile Device:', this.isMobile);

    $(document).ready(function() {
      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });
  });
  }

  getCategoryImage(category: any): string {
    if (category?.thumbnail) {
      return this.isMobile ? this.processImageUrl(category.thumbnail) : category.thumbnail;
    }
    return '../../assets/placeholder.jpg';
  }
  // getCategoryImage(category:any) {
  //   if (category && category.thumbnail) {
  //     return category.thumbnail;
  //   } else {
  //     return '../../assets/placeholder.jpg';
  //   }
  // }

}
