import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Hàm tìm vị trí chuỗi '%2F' và thêm 'thumb_' sau nó
   * @param value Chuỗi cần xử lý
   * @returns Chuỗi sau khi xử lý
   */
  insertThumbAtEncodedSlash(value: string): string {
    if (!value) {
      return '';
    }

    const position = value.indexOf('%2F');
    if (position === -1) {
      return value;
    }

    return value.slice(0, position + 3) + 'thumb_' + value.slice(position + 3);
  }
}
