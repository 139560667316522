import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  private userAgent: string;
  private isMobile: boolean;

  constructor() {
    this.userAgent = window.navigator.userAgent;
    this.isMobile = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(this.userAgent);
  }

  /**
   * Kiểm tra thiết bị có phải là di động không
   */
  public isMobileDevice(): boolean {
    return this.isMobile;
  }

  /**
   * Kiểm tra thiết bị có phải là máy tính không
   */
  public isDesktopDevice(): boolean {
    return !this.isMobile;
  }

  /**
   * Lấy thông tin kích thước màn hình
   */
  public getScreenSize(): { width: number; height: number } {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
}
