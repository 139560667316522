  <div style="position: absolute; left: 90px; top: 0px;">
    <a href="/nghethuat">
      
      <div *ngFor="let category of cat_nghethuat | async">
        <img [src]="getCategoryImage(category)" alt="Nghệ Thuật" title="Nghệ Thuật" width="175" height="91">
      </div>
      
      <!-- <img src="/assets/gioithieu/Logomoi_nowa.jpg" title="Nghệ Thuật">  -->
    
    </a>
  </div>
  <div class="logo">
    <a href="/"><img src="/assets/Home_files/logo.png" title="Trang chủ"></a>
  </div>

  <div class="gioithieu">
    <div class="custom">
      <!-- <p><a href="/introduce" rel="alternate"><img src="/assets/Home_files/gioithieu.jpg" alt="giới thiệu"
            title="Giới thiệu công ty" width="150" height="150"></a></p> -->
      <p><a href="/introduce" rel="alternate">
          <div *ngFor="let category of cat_gioithieu | async">
            <img [src]="getCategoryImage(category)" alt="giới thiệu" title="Giới thiệu công ty" width="150"
              height="150">
          </div>

        </a>
      </p>
    </div>
  </div>
  <div style="position: absolute;
  right: 748px;
  top: 646px ;">
    <a _ngcontent-nml-c138="" target="_blank" href="https://www.youtube.com/@nowa.vietnam"> <img
        src="/assets/gioithieu/youtobe_logo.jpg" title="Giới thiệu qua youtobe"> </a>

  </div>

  <div class="lienhe">
    <div class="custom">
      <p><a target="_blank" href="https://www.facebook.com/nowa.vn?mibextid=ZbWKwL" rel="alternate"><img
            src="/assets/Home_files/fb.png" alt="Liên hệ" title="Liên hệ công ty" width="75" height="75"></a></p>
    </div>
  </div>

  <div class="duan">
    <div class="slide-1">
      <div class="rslides rslides1">
        <ngb-carousel>
          <!-- <ng-template ngbSlide>
            <div class="wrapper">
              <img style="width:300px;height:300px;" [src]="images[0]" title="Các dự án" >
            </div>           
          </ng-template> -->
          <ng-template ngbSlide *ngFor="let category of categories | async">
            <div class="picsum-img-wrapper">
              <a href="/products">
                <img style="width:300px;height:300px;" title="Các dự án" [src]="getCategoryImage(category)"
                  alt="{{category.name}}-{{category.thumbnail}}" />
              </a>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>

      <!-- <ul class="rslides rslides1"> -->
      <!-- <li id="rslides1_s13" class="rslides1_on" style="display: list-item; float: left; position: relative; opacity: 1; z-index: 2; transition: opacity 500ms ease-in-out 0s;">
          <a href="/projects.html"><img style="width:300px;height:300px;" title="Các dự án" alt="tin tuc" src="/assets/TEN/D10.jpg"></a>
        </li> -->


      <!-- <li *ngFor="let category of categories | async" id="rslides1_s1" class="rslides1_on"
          style="display: list-item; float: none; position: absolute; opacity: 0; z-index: 1; -webkit-transition: opacity 500ms ease-in-out; transition: opacity 500ms ease-in-out;">
          <a href="/category/{{ category.slug }}"><img style="width:300px;height:300px;" title="Các dự án"
              [src]="getCategoryImage(category)" itemprop="image" alt="{{category.name}}"></a>
        </li>
        <li *ngFor="let category of categories | async" id="rslides1_s2" 
          style="display: list-item; float: none; position: absolute; opacity: 0; z-index: 1; -webkit-transition: opacity 500ms ease-in-out; transition: opacity 500ms ease-in-out;">
          <a href="/category/{{ category.slug }}"><img style="width:300px;height:300px;" title="Các dự án"
              [src]="getCategoryImage(category)" itemprop="image" alt="{{category.name}}"></a>
        </li>
      </ul> -->
    </div>

  </div>

  <div>
   
  
    <div *ngIf="isMobile">
      <!-- <p>Đang sử dụng ----------------------------------------  điện thoại</p> -->
      <!-- Nội dung cho di động -->
    </div>
    
    <div *ngIf="!isMobile">
      <!-- <p>Đang sử dụng ----------------------------------------  máy tính</p> -->
      <!-- Nội dung cho máy tính -->
    </div>

  </div>