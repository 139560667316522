import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Observable } from 'rxjs';
// import { ResizeService } from 'src/app/size-detector/resize.service';
declare var $: any;
import { delay, map } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: Observable<any[]>;
  searchTerm: string;
  scrHeight:any;
  scrWidth:any;
  isMobile: boolean;

  constructor(
    public db: AngularFireDatabase,
    public globalService: GlobalService,
    public router: Router,
    private title: Title,
    private metaService: Meta,
    private resizeSvc: ResizeService,
    private deviceDetector: DeviceDetectorService,
            private utilsService: UtilsService
  ) {


    // this.getScreenSize();

    

    this.products = db.list('/categories', ref => ref.orderByChild('nghethuat').equalTo(true).limitToLast(200)).valueChanges().pipe(
      map(categories => 
        (categories as any[]).sort((a, b) => a.weight - b.weight) // Sắp xếp tăng dần
      )
    );

    this.globalService.searchTerm.subscribe((term) => {
      this.searchTerm = term;
    });
    
  }

  ngOnInit() {
    this.title.setTitle('Products');
    // this.isMobile = this.deviceDetector.isMobileDevice();
    console.log('Is Mobile Device:', this.isMobile);
    // this.meta.updateTag({ content: 'View all products' }, "name='description'");
    $(document).ready(function() {
        // Full-screen navigation
        var $offcanvasToggle = $('.offcanvas-toggle');
        var $offcanvas = $('.offcanvas');
        $offcanvasToggle.on('click', function () {
          $offcanvas.toggleClass('offcanvas--active');
          $offcanvasToggle.toggleClass('offcanvas-toggle--active');
        });
    });

    // if (this.router.url.includes('product')) {
    //   this.globalService.searchTerm.next('');
    // }
  }

  processImageUrl(url: string): string {
    return this.utilsService.insertThumbAtEncodedSlash(url);
  }

  getProductImage(product: any): string {
    if (product?.thumbnail) {
      return this.isMobile ? this.processImageUrl(product.thumbnail) : product.thumbnail;
    }
    return '../../assets/placeholder.jpg';
  }

  // getProductImage(product:any) {
  //   if (product.thumbnail) {
  //     return product.thumbnail;
  //   } else {
  //     return '../../assets/placeholder.jpg';
  //   }
  // }
}
