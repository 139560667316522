import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
declare var $: any;

import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-introduce',
  templateUrl: './introduce.component.html',
  styleUrls: ['./introduce.component.scss']
})
export class IntroduceComponent implements OnInit {
  size: SCREEN_SIZE;
  
  products: Observable<any[]>;
  // categories: Observable<any[]>;
  category: Observable<any[]>;
  categoryObject: any;
  categoryName: string;
  categoryProducts: Observable<any[]>;
  isMobile: boolean;

  constructor(private resizeSvc: ResizeService,
        public db: AngularFireDatabase,
        private deviceDetector: DeviceDetectorService,
        private utilsService: UtilsService
  ) {
    // this.resizeSvc.onResize$
    // .pipe(delay(10))
    // .subscribe(x => {
    //   this.size = x;
    // });
    this.products = db.list('/products', ref => ref.orderByChild('weight').limitToLast(999)).valueChanges();
    this.categoryObject = {};
    
   }

  ngOnInit(): void {
    // this.title.setTitle('Products');
    // this.meta.updateTag({ content: 'View all products' }, "name='description'");
    // this.isMobile = this.deviceDetector.isMobileDevice();
    console.log('Is Mobile Device:', this.isMobile);

    $(document).ready(function() {
        // Full-screen navigation
        var $offcanvasToggle = $('.offcanvas-toggle');
        var $offcanvas = $('.offcanvas');
        $offcanvasToggle.on('click', function () {
          $offcanvas.toggleClass('offcanvas--active');
          $offcanvasToggle.toggleClass('offcanvas-toggle--active');
        });
    });

    this.category = this.db.list('/categories', ref => ref.orderByChild('gioithieu').equalTo(true)).valueChanges();

    this.category.subscribe((cat:any) => {
      this.categoryObject.slug = cat[0].slug;
      this.categoryObject.name = cat[0].name;
      this.categoryObject.products = Object.keys(cat[0].products);
      this.products.subscribe((p:any) => {
        this.categoryProducts = p.filter((item:any) => {
          return item.category === cat[0].entityKey;
        });
      });

      // this.title.setTitle(this.categoryObject.name);
      // this.meta.addTag({ name: 'description', content: 'View all products in the ' + this.categoryObject.name + ' category' });
    });

    // this.resizeSvc.onResize$
    // .pipe(delay(10))
    // .subscribe(x => {
    //   this.size = x;
    // });
  }

  processImageUrl(url: string): string {
    return this.utilsService.insertThumbAtEncodedSlash(url);
  }

  getProductImage(product: any): string {
    if (product?.thumbnail) {
      return this.isMobile ? this.processImageUrl(product.thumbnail) : product.thumbnail;
    }
    return '../../assets/placeholder.jpg';
  }

  // getProductImage(product:any) {
  //   if (product && product.thumbnail) {
  //     return product.thumbnail;
  //   } else {
  //     return '../../assets/placeholder.jpg';
  //   }
  // }

}
